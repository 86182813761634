import React from "react";
import { Link } from "gatsby";
import { PageText, PageH1 } from "../styledcomponents/layoutstyles";
import {
  CenterP,
  CoverImageStarDiv,
} from "./../styledcomponents/frontpagestyles";

import Layout from "../components/layout";

const NotFoundImage = () => {
  return (
    <Link to="/">
      <CoverImageStarDiv className={"on"} is404={true}>
        <div>
          <div>
            <div>
              <div>
                <div>
                  <div />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div />
      </CoverImageStarDiv>
    </Link>
  );
};

const NotFoundPage = () => (
  <Layout title="404: Not found">
    <PageH1>NOT FOUND!</PageH1>
    <PageText>
      <CenterP>Oh no! We can’t find whatever you're looking for!</CenterP>
      <NotFoundImage />
    </PageText>
  </Layout>
);

export default NotFoundPage;
