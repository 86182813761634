import styled, { keyframes } from "styled-components";
import cover from "./../images/ringondeli.png";
import starring from "./../images/starring.svg";
import RingOnDeliImage from "./../images/remadelogo.svg";

const wobble = keyframes`
  0 {
    transform: rotate(0deg) scale(1);
  }
  25% {
    transform: rotate(-4deg) scale(1.2);
  }
  50% {
    transform: rotate(0deg) scale(1);
  }
  75% {
    transform: rotate(4deg) scale(1.2);
  }
  100% {
    transform: rotate(0deg) scale(1);
  }
`;

const rotate = keyframes`
	0% {
		transform: rotate(0deg);
	}
	50% {
		transform: rotate(180deg);
	}
	100% {
		transform: rotate(360deg);
	}
`;

// these are to fool Safari. The 0.0s are to fool Webpack into not making these identical with the original

const wobble2 = keyframes`
  0 {
    transform: rotate(0.0deg) scale(1);
  }
  25% {
    transform: rotate(-4deg) scale(1.2);
  }
  50% {
    transform: rotate(0.0deg) scale(1);
  }
  75% {
    transform: rotate(4deg) scale(1.2);
  }
  100% {
    transform: rotate(0.0deg) scale(1);
  }
`;

const rotate2 = keyframes`
	0% {
		transform: rotate(0.0deg);
	}
	50% {
		transform: rotate(180.0deg);
	}
	100% {
		transform: rotate(360.0deg);
	}
`;
export const FrontP = styled.p`
  font-size: 24px !important;
  line-height: 36px !important;
  max-width: 600px;
  width: 100%;
  margin: 0 auto !important;
  text-align: center;
`;

export const CenterP = styled.p`
  font-size: 24px !important;
  line-height: 36px !important;
  max-width: 600px;
  width: 100%;
  margin: 0 auto !important;
  text-align: center;
`;

export const DividerP = styled.p`
  text-align: center;
  font-size: 24px !important;
  line-height: 36px !important;
  max-width: 600px;
  width: 100%;
  margin: 0 auto 0 !important;
  text-indent: 0 !important;
  letter-spacing: 24px;
  color: var(--gold);
  user-select: none;
  & + p {
    text-indent: 0 !important;
  }
`;

// export const CoverImageDiv = styled.div`
//   width: 300px;
//   height: 480px;
//   margin: 60px auto;
//   transition: 1s;
//   cursor: pointer;
//   @media screen and (max-width: 767px) {
//     width: 180px;
//     height: 288px;
//     margin: 40px auto;
//   }
//   & div {
//     width: 300px;
//     height: 480px;
//     position: relative;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     background-image: url(${cover});
//     background-size: 100% 100%;
//     box-shadow: 0 0 50px rgba(255, 255, 255, 0.75);
//     transform: rotate(-120deg);
//     opacity: 1;
//     @media screen and (max-width: 767px) {
//       width: 180px;
//       height: 288px;
//     }
//     &:before {
//       content: "";
//       position: absolute;
//       top: 0;
//       left: 0;
//       width: 100%;
//       height: 100%;
//       background: inherit;
//       z-index: -1;
//       transform: rotate(15deg);
//       background-image: url(${cover});
//       box-shadow: 0 0 50px rgba(255, 255, 255, 0.75);
//       opacity: 1;
//     }
//     &:after {
//       content: "";
//       position: absolute;
//       top: 0;
//       left: 0;
//       width: 100%;
//       height: 100%;
//       background: inherit;
//       z-index: -1;
//       transform: rotate(30deg) scale(1);
//       background-image: url(${cover});
//       box-shadow: 0 0 50px rgba(255, 255, 255, 0.75);
//       opacity: 1;
//       transition: 1s;
//     }
//     & div {
//       transform: rotate(45deg);
//       box-shadow: 0 0 50px rgba(45, 58, 141, 0.75);
//       box-shadow: 0 0 50px rgba(255, 255, 255, 0.75);
//       opacity: 1;
//       transition: 1s;
//     }
//   }
//   &:hover {
//     animation: ${wobble} linear 2s infinite;
//     & div {
//       transform: rotate(0deg);
//       &:before {
//         transform: rotate(0deg);
//       }
//       &:after {
//         transform: rotate(0deg);
//       }
//       & div {
//         transform: rotate(0deg);
//         box-shadow: 0 0 20px rgba(45, 58, 141, 1);
//       }
//     }
//   }
// `;

export const CoverImageStarDiv = styled.div`
  width: 100%;
  height: 600px;
  margin: 60px auto;
  transition: 1s;
  cursor: pointer;
  position: relative;
  pointer-events: none;
  @media screen and (max-width: 767px) {
    height: 300px;
    margin: 40px auto;
  }
  & > div:first-of-type {
    animation: ${rotate} linear 100s infinite;
    transition: 0;
    position: absolute;
    width: 800px;
    height: 800px;
    left: calc(50% - 400px);
    top: calc(50% - 400px);
    background-image: url(${starring});
    background-size: 100% 100%;
    /* opacity: 0.75; */
    pointer-events: none;
    @media screen and (max-width: 767px) {
      width: 380px;
      height: 380px;
      left: calc(50% - 190px);
      top: calc(50% - 190px);
    }
    & div {
      transition: 0;
      position: absolute;
      width: 800px;
      height: 800px;
      left: calc(50% - 400px);
      top: calc(50% - 400px);
      background-image: url(${starring});
      background-size: 100% 100%;
      transform: rotate(13.846deg) scale(0.75);
      pointer-events: none;
      @media screen and (max-width: 767px) {
        width: 380px;
        height: 380px;
        left: calc(50% - 190px);
        top: calc(50% - 190px);
      }
    }
    & + div {
      position: absolute;
      transition: 1s;
      animation: ${wobble} ease-in-out 999s infinite;
      top: calc(50% - 240px);
      left: calc(50% - 150px);
      width: 300px;
      height: 480px;
      background-color: ${(props) => (props.is404 ? "none" : "var(--white)")};
      background-image: url(${(props) =>
        props.is404 ? RingOnDeliImage : cover});
      background-size: 100% 100%;
      box-shadow: 0 0 20px rgba(45, 58, 141, 1);
      box-shadow: ${(props) =>
        props.is404 ? "none" : "0 0 20px rgba(0, 0, 0, 0.5)"};
      transform: rotate(0deg) scale(1);
      pointer-events: none;
      @media screen and (max-width: 767px) {
        width: 150px;
        height: 240px;
        left: calc(50% - 75px);
        top: calc(50% - 120px);
      }
    }
  }
  &.on > div {
    animation: ${rotate2} linear 4s infinite;
  }
  &.on > div + div {
    animation: ${wobble2} 2s ease-in-out infinite;
  }
`;
